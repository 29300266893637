import React from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'
import formatDate from '../../../utils/formatDate'

import Timeline from '../../../components/Templates/Timeline'
import NoTranslation from '../../../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

const getVideoData = (page) => {
  const video = page.data

  return {
    sources: [
      {
        url: video.video.url,
        type: 'mp4',
      },
    ],
    caption: video.video_caption.text,
  }
}

const getTimelineData = (page) => {
  return {
    steps: page.data.timeline.map((step) => {
      let newStep = {
        date: formatDate(step.date),
        text: step.content && step.content.text,
      }

      if (step.link_url && step.link_url.url) {
        newStep.link = {
          ...step.link_url,
          external: step.link_url.url.indexOf('http') === 0,
        }
      }

      return newStep
    }),
  }
}

const OurStoryPage = ({ data, pageContext }) => {
  const { prismicOurHistory } = data
  if (!prismicOurHistory) return <NoTranslation></NoTranslation>
  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(prismicOurHistory),
    mantelData: extract.mantel(prismicOurHistory, {
      isThick: true,
      parent: {
        path: localizePath(pageContext.locale, `/who-we-are`),
        text: 'Who we are',
      },
    }),
    videoData: getVideoData(prismicOurHistory),
    timelineData: getTimelineData(prismicOurHistory),
  }

  return <Timeline {...hydratedData} />
}

export default withPreview(OurStoryPage)

export const pageQuery = graphql`
  query HistoryByLocale($locale: String!) {
    prismicOurHistory(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        video {
          url
        }
        video_caption {
          text
        }
        timeline {
          date
          content {
            text
          }
          link_url {
            url
            link_type
            uid
            document {
              ... on PrismicArticle {
                data {
                  article_type
                }
              }
            }
          }
        }
      }
    }
  }
`
