import React from 'react'
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import TimelineSteps from '../../General/TimelineSteps'
import ContentVideo from '../../General/ContentVideo'

const Timeline = ({ seo, mantelData, videoData, timelineData }) => (
  <PageWrap bg={3} seo={seo}>
    <Mantel {...mantelData} />
    <div className="mx-auto max-w-3xl mt-0 mb-8 lg:my-8">
      <ContentVideo {...videoData} />
    </div>
    <div className="mx-auto max-w-xl my-0 lg:my-4">
      <TimelineSteps {...timelineData} />
    </div>
  </PageWrap>
)

export default Timeline
